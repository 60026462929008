import styled from 'styled-components';
import { Container } from '../../styles/commonStyles';
import { Link } from 'react-router-dom';

const FooterWrapper = styled.footer`
  width: 100%;
  padding: 1.5rem 0;
  color: ${props => props.theme.colors.secondary};
  backdrop-filter: blur(10px);
  
  @media (max-width: 768px) {
    padding: 1rem 0;
  }
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  
  p {
    margin-right: 0.5rem;
  }
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.8rem;
    
    p {
      font-size: 0.9rem;
      opacity: 0.8;
      margin-right: 0;
    }
  }
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.accent};
  text-decoration: none;
  font-size: 0.95rem;
  transition: opacity 0.2s ease;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -2px;
    left: 0;
    background-color: ${props => props.theme.colors.accent};
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
  
  &:hover::after {
    transform: scaleX(1);
  }
  
  &:hover {
    opacity: 0.8;
  }
  
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <Container>
        <FooterContent>
          <p>&copy; 2024 CEMARDZ. All rights reserved.</p>
          <StyledLink to="/license">License</StyledLink>
        </FooterContent>
      </Container>
    </FooterWrapper>
  );
};

export default Footer; 