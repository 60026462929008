export const lightTheme = {
  colors: {
    background: '#ffffff',
    text: '#000000',
    accent: '#0071e3',
    secondary: '#6e6e73',
    tertiary: '#f5f5f7',
    card: {
      background: '#ffffff',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
      hover: {
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.12)',
        transform: 'translateY(-2px)'
      }
    },
    border: 'rgba(0, 0, 0, 0.1)',
    gradient: 'linear-gradient(145deg, #ffffff, #f5f5f7)',
    nav: 'rgba(255, 255, 255, 0.8)',
    footer: '#f5f5f7',
    cardHover: '#f5f5f7',
    shadow: 'rgba(0, 0, 0, 0.1)'
  }
}; 