import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Container, GradientText } from '../styles/commonStyles';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useLanguage } from '../contexts/LanguageContext';

const NotFoundContainer = styled(Container)`
  padding-top: calc(70px + 4rem);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Title = styled(motion.h1)`
  font-size: 8rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 5rem;
  }
`;

const Message = styled(motion.p)`
  font-size: 1.5rem;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    padding: 0 1rem;
  }
`;

const Button = styled(motion.button)`
  padding: 1rem 2rem;
  font-size: 1.1rem;
  background: ${props => props.theme.colors.accent};
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px ${props => props.theme.colors.shadow};
  }

  @media (max-width: 768px) {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
`;

const NotFound = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();

  useEffect(() => {
    document.title = `404 - CEMARDZ`;
    return () => {
      document.title = 'CEMARDZ';
    };
  }, []);

  const text = {
    ko: {
      message: "페이지를 찾을 수 없습니다",
      button: "홈으로 돌아가기"
    },
    en: {
      message: "Page not found",
      button: "Back to Home"
    }
  }[language];

  return (
    <NotFoundContainer>
      <Title
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <GradientText>404</GradientText>
      </Title>
      <Message
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {text.message}
      </Message>
      <Button
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        onClick={() => navigate('/')}
        whileHover={{ y: -3 }}
        whileTap={{ scale: 0.98 }}
      >
        {text.button}
      </Button>
    </NotFoundContainer>
  );
};

export default NotFound; 