import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Container, GradientText } from '../styles/commonStyles';
import { useLanguage } from '../contexts/LanguageContext';
import { Heart, Shield, Users, Sparkles } from 'lucide-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Home } from 'lucide-react';
import { FaFireExtinguisher } from "react-icons/fa";
import CountUp from 'react-countup';

const DonationContainer = styled(Container)`
  padding-top: calc(70px + 2rem);
  min-height: 100vh;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 4rem;
`;

const Title = styled(motion.h1)`
  font-size: 2.5rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Subtitle = styled(motion.p)`
  color: ${props => props.theme.colors.secondary};
  font-size: 1.1rem;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.8;

  @media (max-width: 768px) {
    font-size: 0.95rem;
    padding: 0 1rem;
  }
`;

const DonationGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  padding: 0 1rem;
  margin-top: 3rem;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    margin-top: 2rem;
  }
`;

const DonationCard = styled(motion.div)`
  background: ${props => props.theme.colors.card.background};
  border: ${props => props.theme.colors.card.border};
  border-radius: 16px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  ${props => props.$inactive && `
    opacity: 0.7;
    background: ${props.theme.colors.card.background}80;
    
    &:hover {
      transform: none;
    }
  `}
`;

const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background: ${props => props.theme.colors.accent};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  svg {
    width: ${props => props.$isFirefighter ? '26px' : '24px'};
    height: ${props => props.$isFirefighter ? '26px' : '24px'};
  }
`;

const CardTitle = styled.h3`
  font-size: 1.3rem;
  color: ${props => props.theme.colors.text};
  margin-bottom: 0.2rem;
`;

const CardDescription = styled.p`
  color: ${props => props.theme.colors.secondary};
  font-size: 0.95rem;
  line-height: 1.6;
  flex-grow: 1;
`;

const ProgressSection = styled.div`
  margin-top: auto;
`;

const ProgressLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
  font-size: 0.9rem;
  color: ${props => props.theme.colors.text};

  .amount, .percentage {
    color: ${props => props.theme.colors.accent};
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 2px;
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background: ${props => props.theme.colors.tertiary};
  border-radius: 4px;
  overflow: hidden;
`;

const Progress = styled(motion.div)`
  width: 0%;
  height: 100%;
  background: ${props => props.theme.colors.accent};
  border-radius: 4px;
`;

const NavigationButtons = styled(motion.div)`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 4rem;
  padding-bottom: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    gap: 0.8rem;
  }
`;

const NavButton = styled(motion.button)`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  background: ${props => props.$primary ? props.theme.colors.accent : props.theme.colors.card.background};
  color: ${props => props.$primary ? 'white' : props.theme.colors.text};
  border: 1px solid ${props => props.$primary ? 'transparent' : props.theme.colors.border};

  &:hover {
    transform: translateY(-2px);
    background: ${props => props.$primary ? props.theme.colors.accent : props.theme.colors.cardHover};
  }

  svg {
    width: 18px;
    height: 18px;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 300px;
    justify-content: center;
    padding: 0.7rem 1.2rem;
    font-size: 0.95rem;
  }
`;

const TotalAmount = styled(motion.div)`
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 2rem 0 4rem;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const ProgressCard = styled(motion.div)`
  text-align: center;
  padding: 2.5rem;
  background: ${props => props.theme.colors.card.background};
  border: ${props => props.theme.colors.card.border};
  border-radius: 16px;
  max-width: 500px;
  width: 100%;
  position: relative;
  overflow: hidden;
  
  background: linear-gradient(
    135deg, 
    ${props => props.theme.colors.accent}15,
    ${props => props.theme.colors.card.background}
  );
  
  h3 {
    color: ${props => props.theme.colors.secondary};
    font-size: 1.1rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    position: relative;
    z-index: 1;
  }

  .progress-info {
    margin-top: 1rem;
    font-size: 1.3rem;
    color: ${props => props.theme.colors.accent};
    font-weight: 600;
    position: relative;
    z-index: 1;
    
    .percent {
      font-size: 1.1rem;
      margin-left: 4px;
    }
  }

  @media (max-width: 768px) {
    padding: 2rem;
    
    h3 {
      font-size: 1rem;
      margin-bottom: 1.5rem;
    }
  }
`;

const GoalProgress = styled.div`
  width: 100%;
  height: 6px;
  background: ${props => props.theme.colors.tertiary};
  border-radius: 8px;
  margin-top: 1.5rem;
  overflow: hidden;
`;

const GoalProgressBar = styled(motion.div)`
  width: 0%;
  height: 100%;
  background: ${props => props.theme.colors.accent};
  border-radius: 4px;
`;

const Donation = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Donation - CEMARDZ`;
    return () => {
      document.title = 'CEMARDZ';
    };
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
        delayChildren: 0.3
      }
    }
  };

  const cardVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    show: { 
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15
      }
    }
  };

  const amountVariants = {
    hidden: { opacity: 0, y: 20 },
    show: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  const donations = {
    firefighter: 0,
    family: 0,
    community: 0,
    future: 0
  };

  const totalDonation = Object.values(donations).reduce((sum, amount) => sum + amount, 0);
  
  const goalAmount = 5000000;
  
  const progressPercentage = Math.floor((totalDonation / goalAmount) * 100);

  const distributionPercentages = {
    firefighter: Math.round((donations.firefighter / totalDonation) * 100),
    family: Math.round((donations.family / totalDonation) * 100),
    community: Math.round((donations.community / totalDonation) * 100),
    future: Math.round((donations.future / totalDonation) * 100)
  };

  const content = {
    ko: {
      title: "소방관 후원",
      subtitle: "앱 수익의 일부를 소방관들을 위해 기부합니다.",
      totalLabel: "현재까지 총 후원 금액",
      goalLabel: "목표 후원 금액",
      progressLabel: "목표 달성률",
      cards: [
        {
          icon: <FaFireExtinguisher />,
          title: "소방관 지원",
          description: "소방관들의 안전 장비 구매와 복지 향상을 위해 수익의 일부를 기부합니다.",
          progress: distributionPercentages.firefighter,
          amount: donations.firefighter
        },
        {
          icon: <Heart />,
          title: "가족 지원",
          description: "순직 소방관 가족들을 위한 지원 프로그램에 참여하고 있습니다.",
          progress: distributionPercentages.family,
          amount: donations.family
        },
        {
          icon: <Users />,
          title: "커뮤니티 참여",
          description: "소방관 커뮤니티 행사와 교육 프로그램을 지원합니다.",
          progress: distributionPercentages.community,
          amount: donations.community
        },
        {
          icon: <Sparkles />,
          title: "미래 계획",
          description: "더 많은 소방관들을 지원하기 위해 지속적으로 노력하고 있습니다.",
          progress: distributionPercentages.future,
          amount: donations.future
        }
      ]
    },
    en: {
      title: "Firefighter Support",
      subtitle: "We donate part of our app revenue to support firefighters.",
      totalLabel: "Current Donation Amount",
      goalLabel: "Goal Amount",
      progressLabel: "Progress to Goal",
      cards: [
        {
          icon: <FaFireExtinguisher />,
          title: "Firefighter Support",
          description: "We donate part of our revenue to improve firefighter safety equipment and welfare.",
          progress: distributionPercentages.firefighter,
          amount: donations.firefighter
        },
        {
          icon: <Heart />,
          title: "Family Support",
          description: "We participate in support programs for families of fallen firefighters.",
          progress: distributionPercentages.family,
          amount: donations.family
        },
        {
          icon: <Users />,
          title: "Community Engagement",
          description: "We support firefighter community events and educational programs.",
          progress: distributionPercentages.community,
          amount: donations.community
        },
        {
          icon: <Sparkles />,
          title: "Future Plans",
          description: "We continue to work towards supporting more firefighters.",
          progress: distributionPercentages.future,
          amount: donations.future
        }
      ]
    }
  };

  const formatAmount = (amount) => {
    return new Intl.NumberFormat(language === 'ko' ? 'ko-KR' : 'en-US', {
      style: 'currency',
      currency: language === 'ko' ? 'KRW' : 'USD',
      maximumFractionDigits: 0
    }).format(amount);
  };

  return (
    <DonationContainer>
      <Header>
        <Title
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <GradientText>{content[language].title}</GradientText>
        </Title>
        <Subtitle
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {content[language].subtitle}
        </Subtitle>
      </Header>

      <TotalAmount
        variants={containerVariants}
        initial="hidden"
        animate="show"
      >
        <ProgressCard
          as={motion.div}
          variants={amountVariants}
        >
          <h3>{language === 'ko' ? '후원 진행률' : 'Donation Progress'}</h3>
          <GoalProgress>
            <GoalProgressBar
              initial={{ width: 0 }}
              animate={{ width: `${progressPercentage}%` }}
              transition={{ duration: 2, delay: 0.8, ease: "easeOut" }}
            />
          </GoalProgress>
          <div className="progress-info">
            <CountUp
              end={progressPercentage}
              duration={2}
              delay={0.8}
              separator=","
            />
            <span className="percent">%</span>
          </div>
        </ProgressCard>
      </TotalAmount>

      <DonationGrid
        variants={containerVariants}
        initial="hidden"
        animate="show"
      >
        {content[language].cards.map((card, index) => (
          <DonationCard
            key={index}
            variants={cardVariants}
            $inactive={card.progress === 0}
          >
            <IconWrapper $isFirefighter={index === 0}>
              {card.icon}
            </IconWrapper>
            <CardTitle>{card.title}</CardTitle>
            <CardDescription>{card.description}</CardDescription>
            <ProgressSection>
              <ProgressLabel>
                <span className="percentage">
                  {card.progress > 0 ? (
                    <>
                      <CountUp
                        end={card.progress}
                        duration={2}
                        delay={0.8 + (index * 0.2)}
                        separator=","
                      />
                      %
                    </>
                  ) : (
                    language === 'ko' ? '준비중' : 'Coming Soon'
                  )}
                </span>
                <span className="amount">
                  {card.progress > 0 ? (
                    <CountUp
                      end={card.amount}
                      duration={2}
                      delay={0.8 + (index * 0.2)}
                      separator=","
                      prefix={language === 'ko' ? '₩' : '$'}
                    />
                  ) : '-'}
                </span>
              </ProgressLabel>
              <ProgressBar>
                <Progress
                  initial={{ width: 0 }}
                  animate={{ width: `${card.progress}%` }}
                  transition={{
                    duration: 2,
                    delay: 0.8 + (index * 0.2),
                    ease: "easeOut"
                  }}
                />
              </ProgressBar>
            </ProgressSection>
          </DonationCard>
        ))}
      </DonationGrid>

      <NavigationButtons
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 1.2 }}
      >
        <NavButton 
          onClick={() => navigate(-1)}
          whileHover={{ y: -3 }}
          whileTap={{ scale: 0.98 }}
        >
          <ArrowLeft />
          {language === 'ko' ? '뒤로 가기' : 'Go Back'}
        </NavButton>
        <NavButton 
          $primary 
          onClick={() => navigate('/')}
          whileHover={{ y: -3 }}
          whileTap={{ scale: 0.98 }}
        >
          <Home />
          {language === 'ko' ? '홈으로' : 'Home'}
        </NavButton>
      </NavigationButtons>
    </DonationContainer>
  );
};

export default Donation; 