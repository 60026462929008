import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Container, GradientText } from '../styles/commonStyles';
import { Construction, ArrowLeft, Home } from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../locales/translations';

const ComingSoonContainer = styled(Container)`
  padding-top: calc(70px + 4rem);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const IconWrapper = styled(motion.div)`
  margin-bottom: 2rem;
  color: ${props => props.theme.colors.accent};
  
  svg {
    width: 64px;
    height: 64px;

    @media (max-width: 768px) {
      width: 48px;
      height: 48px;
    }
  }
`;

const Title = styled(motion.h1)`
  font-size: 2.5rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Description = styled(motion.p)`
  color: ${props => props.theme.colors.secondary};
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.8;

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0 1rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.8rem;
  }
`;

const Button = styled(motion.button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  border: none;
  background: ${props => props.$primary ? props.theme.colors.accent : 'transparent'};
  color: ${props => props.$primary ? 'white' : props.theme.colors.text};
  border: 1px solid ${props => props.$primary ? 'transparent' : props.theme.colors.border};
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    background: ${props => props.$primary ? props.theme.colors.accent : props.theme.colors.cardHover};
  }

  svg {
    width: 18px;
    height: 18px;
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    padding: 0.7rem 1.2rem;
    font-size: 0.95rem;
  }
`;

const ComingSoon = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { language } = useLanguage();
  const t = translations.comingSoon;
  
  const getProjectName = () => {
    switch(projectId) {
      case 'flutter':
        return 'Flutter';
      case 'android':
        return 'Android';
      case 'ios':
        return 'iOS';
      case 'macos':
        return 'MacOS';
      case 'dapp':
        return 'DApp';
      case 'react':
        return 'React Native';
      default:
        return projectId;
    }
  };

  const projectName = getProjectName();

  return (
    <ComingSoonContainer>
      <IconWrapper
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Construction />
      </IconWrapper>
      <Title
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}
      >
        {t.title[language]} <GradientText>Soon</GradientText>
      </Title>
      <Description
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {t.description.line1[language].replace('{projectName}', projectName)}
        <br />
        {t.description.line2[language]}
      </Description>
      <ButtonContainer>
        <Button
          onClick={() => navigate(-1)}
          whileHover={{ y: -2 }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <ArrowLeft /> {t.buttons.back[language]}
        </Button>
        <Button
          $primary
          onClick={() => navigate('/')}
          whileHover={{ y: -2 }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <Home /> {t.buttons.home[language]}
        </Button>
      </ButtonContainer>
    </ComingSoonContainer>
  );
};

export default ComingSoon; 