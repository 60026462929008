import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Card = styled(motion.div).attrs({
  className: 'card'
})`
  background: ${({ theme }) => theme.colors.card.background};
  border: ${({ theme }) => theme.colors.card.border};
  box-shadow: ${({ theme }) => theme.colors.card.boxShadow};
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  padding: 1.5rem;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    box-shadow: ${({ theme }) => theme.colors.card.hover.boxShadow};
    transform: ${({ theme }) => theme.colors.card.hover.transform};
  }
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

export const GradientText = styled.span.attrs({
  className: 'gradient-text'
})`
  color: ${props => props.theme.colors.accent};
`;

export const CardWrapper = styled.div`
  background: ${({ theme }) => theme.card.background};
  border: ${({ theme }) => theme.card.border};
  box-shadow: ${({ theme }) => theme.card.boxShadow};
  transition: all 0.3s ease;

  &:hover {
    box-shadow: ${({ theme }) => theme.card.hover.boxShadow};
    transform: ${({ theme }) => theme.card.hover.transform};
  }
`; 