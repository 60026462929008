export const translations = {
  nav: {
    home: {
      ko: "홈",
      en: "Home"
    },
    about: {
      ko: "소개",
      en: "About"
    },
    projects: {
      ko: "프로젝트",
      en: "Projects"
    },
    contact: {
      ko: "연락하기",
      en: "Contact"
    }
  },
  home: {
    title: {
      ko: {
        first: "프로그래밍이 재미있는 개발자",
        second: "프로그래밍이",
        third: "재미있는 개발자"
      },
      en: "Developer who finds programming fun"
    },
    subtitle: {
      ko: "만들고 싶은 것을 만들고 있는 개발자입니다.",
      en: "A developer bringing ideas to life through code."
    },
    stats: {
      codeLines: {
        ko: "지금까지 마신 커피",
        en: "Coffee Consumed"
      },
      unit: {
        ko: "잔 +",
        en: "Cups +"
      },
      projects: {
        ko: "3+ 프로젝트",
        en: "3+ Projects"
      },
      deployments: {
        ko: "5+ 배포",
        en: "5+ Deployments"
      }
    },
    cards: {
      stack: {
        title: {
          ko: "개발 스택",
          en: "Tech Stack"
        },
        description: {
          ko: "주요 기술 스택",
          en: "Core technologies and expertise"
        }
      },
      projects: {
        title: {
          ko: "깃허브 프로젝트 통계",
          en: "GitHub Statistics"
        },
        description: {
          ko: "프로젝트 현황",
          en: "Project overview and metrics"
        }
      },
      recent: {
        title: {
          ko: "최근 프로젝트",
          en: "Recent Projects"
        },
        items: [
          {
            ko: "영어 단어 외우기 개발중",
            en: "English Vocabulary App (In Development)"
          },
          {
            ko: "준비중",
            en: "In Progress"
          },
          {
            ko: "준비중",
            en: "Coming Soon"
          }
        ]
      },
      skills: {
        title: {
          ko: "기술 스택",
          en: "Technical Skills"
        },
        items: {
          frontend: "Frontend",
          backend: "Backend",
          database: "Database",
          devops: "DevOps"
        }
      }
    }
  },
  about: {
    title: {
      ko: "About Me",
      en: "About Me"
    },
    subtitle: {
      ko: "새로운 도전과 배움을 즐기는 개발자입니다.",
      en: "I am a developer who enjoys new challenges and learning."
    },
    intro: {
      cemardz: {
        title: {
          ko: "cemardz",
          en: "cemardz"
        },
        content: {
          ko: "Create Meaningful and Reliable Development Zones",
          en: "Create Meaningful and Reliable Development Zones"
        }
      },
      experience: {
        title: {
          ko: "경험",
          en: "Experience"
        },
        content: {
          ko: "다양한 아이디어와 경험을 바탕으로 복잡한 문제를 해결하고 새로운 길을 찾아 나가는 과정을 진심으로 즐깁니다. 끊임없는 시도를 통해 문제를 분석하고 해결 방안을 모색하는 일에 보람을 느끼며, 이를 통해 한 걸음씩 발전해 나가는 것이 즐겁습니다.",
          en: "I truly enjoy the process of solving complex problems and discovering new paths, drawing on diverse ideas and experiences. I find fulfillment in analyzing problems and exploring solutions through continuous efforts, and I take joy in growing step by step through this journey."
        }
      },
      passion: {
        title: {
          ko: "열정",
          en: "Passion"
        },
        content: {
          ko: "새로운 기술을 배우고 이를 실제로 적용해 보는 과정을 매우 즐깁니다. 배움을 통해 얻은 지식과 경험을 활용해 문제를 해결하고, 이를 기반으로 더욱 깊이 있는 이해와 전문성을 쌓아가는 데 큰 보람을 느끼고 있습니다.",
          en: "I greatly enjoy learning new technologies and applying them in practice. I find immense fulfillment in solving problems using the knowledge and experience gained through learning, and building deeper understanding and expertise on this foundation."
        }
      }
    },
    philosophy: {
      development: {
        title: {
          ko: "개발 철학",
          en: "Development Philosophy"
        },
        content: {
          ko: "기술은 사람을 위한 도구입니다. 제가 만드는 코드와 시스템은 사람들에게 긍정적인 변화를 가져올 수 있어야 한다고 믿습니다. 그래서 항상 \"내가 만든 기술이 사람들에게 어떤 영향을 줄까?\"를 고민하며, 인간 중심의 기술 개발을 지향합니다.",
          en: "Technology is a tool for people. I believe that the code and systems I create should bring positive change to people's lives. That’s why I always ask myself, \"What kind of impact will my technology have on people?\" and strive to develop human-centered technology."
        }
      },
      ethicalResponsibility: {
        title: {
          ko: "윤리적 책임",
          en: "Ethical Responsibility"
        },
        content: {
          ko: "기술의 힘은 막대하며, 그것이 사용되는 방식은 세상에 깊은 영향을 미칩니다. 저는 \"무엇을 할 수 있는가\"보다 \"무엇을 해야 하는가\"를 중심에 두고 고민하며, 개인정보 보호와 보안, 공익을 고려하는 윤리적 기준을 엄격히 유지합니다.",
          en: "The power of technology is immense, and the way it is used profoundly impacts the world. I place greater emphasis on \\\"what should be done\\\" rather than \\\"what can be done,\\\" and I strictly adhere to ethical standards that prioritize privacy, security, and the public good."
        }
      },
      valueOfKnowledgeSharing: {
        title: {
          ko: "지식과 공유의 가치",
          en: "Value of Knowledge Sharing"
        },
        content: {
          ko: "지식을 배우고 탐구하며, 이를 나누는 것이 개발자로서의 중요한 가치라고 믿고 있습니다. 오픈 소스 프로젝트에 기여하고, 경험과 통찰을 공유하며, 커뮤니티와 함께 성장합니다. 단, 항상 저작권과 사생활 등 타인의 권리를 존중하는 원칙을 지킵니다.",
          en: "I believe that learning, exploring, and sharing knowledge are essential values as a developer. I contribute to open-source projects, share my experiences and insights, and grow together with the community. However, I always adhere to the principles of respecting others' rights, such as copyright and privacy."
        }
      },
      transparencyAndTrust: {
        title: {
          ko: "투명성과 신뢰",
          en: "Transparency and Trust"
        },
        content: {
          ko: "신뢰는 개발자의 가장 중요한 자산 중 하나입니다. 저는 솔직하고 투명한 태도로 프로젝트의 과정과 결과를 명확히 설명하며, 신뢰를 쌓아갑니다. 이는 기술적 성과를 넘어 모든 관계에서의 기본적인 원칙으로 삼고 있습니다.",
          en: "Trust is one of the most important assets for a developer. I build trust by clearly explaining the process and outcomes of projects with honesty and transparency. This principle goes beyond technical achievements and serves as a fundamental value in all relationships."
        }
      },
      contributingToABetterWorld: {
        title: {
          ko: "더 나은 세상을 위한 기여",
          en: "Contributing to a Better World"
        },
        content: {
          ko: "기술은 단순히 경제적 이익을 위한 수단이 아니라, 세상을 더 나은 곳으로 만드는 도구여야 한다고 생각합니다. 저는 사회적, 환경적, 그리고 인간적인 문제를 해결하는 데 기술을 활용하며, 이를 통해 긍정적인 변화를 만들어내는 데 헌신하고 싶습니다.",
          en: "I believe that technology should not merely be a means for economic gain but a tool to make the world a better place. I strive to use technology to address social, environmental, and human issues, dedicating myself to creating positive change through these efforts."
        }
      },
      flexibilityAndInnovation: {
        title: {
          ko: "유연성과 혁신",
          en: "Flexibility and Innovation"
        },
        content: {
          ko: "정해진 틀에 얽매이지 않고, 문제를 새로운 관점에서 바라보는 유연함을 중요하게 생각합니다. 끊임없이 도전하며, 창의적이고 혁신적인 방식으로 문제를 해결합니다. 이를 통해 보다 지속 가능한 솔루션을 만들어가는 것을 목표로 합니다.",
          en: "I value flexibility in approaching problems from new perspectives, without being bound by predefined frameworks. I continuously challenge myself, solving problems in creative and innovative ways. Through this, I aim to develop more sustainable solutions."
        }
      }
    }
  },
  contact: {
    title: {
      ko: "Let's Connect",
      en: "Let's Connect"
    },
    subtitle: {
      line1: {
        ko: "궁금한 점 혹은 연락이 필요하시면 언제든 연락해 주세요.",
        en: "Have questions or want to get in touch?"
      },
      line2: {
        ko: "언제든 연락해 주시면 함께 이야기하고 싶습니다.",
        en: "I'd love to hear from you and discuss your ideas."
      }
    },
    email: {
      copy: {
        ko: "이메일 주소 복사",
        en: "Copy email address"
      }
    },
    categories: {
      project: {
        title: {
          ko: "프로젝트 문의",
          en: "Project Inquiry"
        },
        description: {
          ko: "새로운 프로젝트나 협업 제안에 대해 논의하고 싶으신가요?",
          en: "Interested in discussing new projects or collaboration opportunities?"
        },
        details: [
          {
            title: {
              ko: "1. 프로젝트 개요",
              en: "1. Project Overview"
            },
            items: {
              ko: [
                "프로젝트의 목적과 핵심 목표",
                "현재 상태(아이디어 단계, 진행 중, 완료 후 유지보수 등)"
              ],
              en: [
                "Project goals and core objectives",
                "Current stage (concept, in progress, maintenance, etc.)"
              ]
            }
          },
          {
            title: {
              ko: "2. 예상 기간 및 예산",
              en: "2. Timeline and Budget"
            },
            items: {
              ko: [
                "프로젝트 예상 시작일 및 종료일",
                "예산 범위"
              ],
              en: [
                "Expected project start and end dates",
                "Budget considerations"
              ]
            }
          },
          {
            title: {
              ko: "3. 기술 스택 요구사항",
              en: "3. Technical Requirements"
            },
            items: {
              ko: [
                "사용하고자 하는 기술이나 툴",
                "���존 구축된 시스템과의 연동 여부"
              ],
              en: [
                "Preferred technologies and tools",
                "Integration needs with existing systems"
              ]
            }
          },
          {
            title: {
              ko: "4. 선호하는 연락 방법",
              en: "4. Preferred Contact Method"
            },
            items: {
              ko: [
                "이메일, 전화, 화상 미팅 등"
              ],
              en: [
                "Email, phone, video conference, etc."
              ]
            }
          },
          {
            title: {
              ko: "5. 첨부 자료 (선택 사항)",
              en: "5. Attachments (Optional)"
            },
            items: {
              ko: [
                "포트폴리오, 참고 자료, 또는 프로젝트와 관련된 문서"
              ],
              en: [
                "Portfolio, reference materials, or project documentation"
              ]
            }
          }
        ]
      },
      recruitment: {
        title: {
          ko: "채용 및 스카우트",
          en: "Recruitment and Opportunities"
        },
        description: {
          ko: "개발자 채용이나 새로운 기회를 제안하고 싶으신가요?",
          en: "Looking to hire developers or discuss career opportunities?"
        },
        details: [
          {
            title: {
              ko: "1. 회사 소개",
              en: "1. Company Profile"
            },
            items: {
              ko: [
                "회사의 비전과 주요 사업",
                "팀 문화 및 업무 환경"
              ],
              en: [
                "Company vision and core business",
                "Team culture and work environment"
              ]
            }
          },
          {
            title: {
              ko: "2. 포지션 상세 내용",
              en: "2. Position Details"
            },
            items: {
              ko: [
                "채용하려는 포지션의 역할 및 책임",
                "요구하는 기술 스택과 경력"
              ],
              en: [
                "Role responsibilities and expectations",
                "Required technical skills and experience"
              ]
            }
          },
          {
            title: {
              ko: "3. 처우 및 복지",
              en: "3. Compensation Package"
            },
            items: {
              ko: [
                "연봉, 복지 혜택, 근무 조건"
              ],
              en: [
                "Salary, benefits, and working conditions"
              ]
            }
          },
          {
            title: {
              ko: "4. 채용 프로세스",
              en: "4. Hiring Process"
            },
            items: {
              ko: [
                "서류 전형, 면접, 과제 제출 등"
              ],
              en: [
                "Resume review, interviews, technical assessments, etc."
              ]
            }
          },
          {
            title: {
              ko: "5. 첨부 자료 (선택 사항)",
              en: "5. Attachments (Optional)"
            },
            items: {
              ko: [
                "회사 소개 자료 또는 JD(Job Description)"
              ],
              en: [
                "Company overview or detailed job description"
              ]
            }
          }
        ]
      },
      meeting: {
        title: {
          ko: "미팅 요청",
          en: "Meeting Request"
        },
        description: {
          ko: "직접 만나서 이야기를 나누고 싶으신가요?",
          en: "Would you like to schedule a discussion?"
        },
        details: [
          {
            title: {
              ko: "1. 미팅 목적",
              en: "1. Meeting Purpose"
            },
            items: {
              ko: [
                "논의하고자 하는 주제"
              ],
              en: [
                "Topics for discussion"
              ]
            }
          },
          {
            title: {
              ko: "2. 선호하는 미팅 방식",
              en: "2. Preferred Format"
            },
            items: {
              ko: [
                "대면 미팅, 비대면 화상 회의 등"
              ],
              en: [
                "In-person meeting, video call, etc."
              ]
            }
          },
          {
            title: {
              ko: "3. 희망 일정 및 소요 시간",
              en: "3. Scheduling"
            },
            items: {
              ko: [
                "가능한 날짜와 시간, 예상 소요 시간"
              ],
              en: [
                "Preferred dates, times, and expected duration"
              ]
            }
          },
          {
            title: {
              ko: "4. 미팅 아젠다 (선택 사항)",
              en: "4. Meeting Agenda (Optional)"
            },
            items: {
              ko: [
                "미리 논의할 내용을 정리해 주시면 더욱 효율적인 미팅이 가능합니다"
              ],
              en: [
                "Outline of discussion points for a more productive meeting"
              ]
            }
          }
        ]
      },
      appInquiry: {
        title: {
          ko: "앱 관련 문의",
          en: "App Support"
        },
        description: {
          ko: "앱 사용 중 발생한 문제나 개선사항에 대해 알려주세요.",
          en: "Need help with app-related issues or have suggestions for improvements?"
        },
        details: [
          {
            title: {
              ko: "1. 문의 유형",
              en: "1. Inquiry Type"
            },
            items: {
              ko: [
                "버그 리포트",
                "기능 개선 제안",
                "사용성 피드백",
                "기타 문의사항"
              ],
              en: [
                "Bug report",
                "Feature enhancement request",
                "Usability feedback",
                "General inquiries"
              ]
            }
          },
          {
            title: {
              ko: "2. 상세 내용",
              en: "2. Detailed Description"
            },
            items: {
              ko: [
                "발생한 문제나 개선 필요한 부분",
                "재현 방법 (버그의 경우)",
                "사용 중인 기기 및 OS 버전"
              ],
              en: [
                "Description of the issue or area for improvement",
                "Steps to reproduce (for bugs)",
                "Device and OS version details"
              ]
            }
          },
          {
            title: {
              ko: "3. 첨부 자료",
              en: "3. Supporting Materials"
            },
            items: {
              ko: [
                "스크린샷이나 동영상",
                "오류 메시지",
                "로그 데이터"
              ],
              en: [
                "Screenshots or screen recordings",
                "Error messages",
                "System logs"
              ]
            }
          }
        ]
      },
      appFeedback: {
        title: {
          ko: "앱 피드백",
          en: "App Feedback"
        },
        description: {
          ko: "앱을 사용하면서 느낀 점이나 제안사항을 공유해주세요.",
          en: "Share your experience and suggestions to help us improve."
        },
        details: [
          {
            title: {
              ko: "1. 피드백 유형",
              en: "1. Feedback Category"
            },
            items: {
              ko: [
                "UI/UX 개선 제안",
                "새로운 기능 요청",
                "사용자 경험 공유",
                "성능 관련 의견"
              ],
              en: [
                "UI/UX improvement suggestions",
                "New feature requests",
                "User experience insights",
                "Performance feedback"
              ]
            }
          },
          {
            title: {
              ko: "2. 상세 내용",
              en: "2. Detailed Information"
            },
            items: {
              ko: [
                "현재 상황 설명",
                "개선 아이디어나 제안",
                "기대하는 변화"
              ],
              en: [
                "Current situation overview",
                "Suggested improvements",
                "Expected outcomes"
              ]
            }
          },
          {
            title: {
              ko: "3. 사용 환경",
              en: "3. Usage Context"
            },
            items: {
              ko: [
                "사용 중인 앱 버전",
                "디바이스 정보",
                "주요 용 기능"
              ],
              en: [
                "Current app version",
                "Device specifications",
                "Frequently used features"
              ]
            }
          }
        ]
      },
      technical: {
        title: {
          ko: "기술 문의",
          en: "Technical Support"
        },
        description: {
          ko: "기술과 관련된 궁금증이나 문제를 해결하는 데 도움을 드릴 수 있습니다.",
          en: "Let us help you with any technical questions or challenges."
        },
        details: [
          {
            title: {
              ko: "1. 질문하고자 하는 기술 분야",
              en: "1. Technical Area"
            },
            items: {
              ko: [
                "예: 웹 개발, 데이터베이스, 보안 등"
              ],
              en: [
                "e.g., Web development, databases, security, etc."
              ]
            }
          },
          {
            title: {
              ko: "2. 구체적인 문제 상황",
              en: "2. Issue Description"
            },
            items: {
              ko: [
                "발생한 문제의 배경과 상황 설명"
              ],
              en: [
                "Detailed explanation of the technical challenge"
              ]
            }
          },
          {
            title: {
              ko: "3. 시도해본 해결 방법",
              en: "3. Previous Solutions"
            },
            items: {
              ko: [
                "어떤 방법을 시도했는지, 어떤 결과를 얻었는지"
              ],
              en: [
                "What solutions have you tried and their outcomes?"
              ]
            }
          },
          {
            title: {
              ko: "4. 첨부 자료 (선택 사항)",
              en: "4. Supporting Materials (Optional)"
            },
            items: {
              ko: [
                "관련 코드, 에러 메시지, 스크린샷"
              ],
              en: [
                "Relevant code snippets, error logs, screenshots"
              ]
            }
          }
        ]
      }
    }
  },
  comingSoon: {
    title: {
      ko: "Coming",
      en: "Coming"
    },
    description: {
      line1: {
        ko: "{projectName} 프로젝트를 준비 중입니다.",
        en: "The {projectName} project is under development."
      },
      line2: {
        ko: "곧 멋진 모습으로 찾아뵙겠습니다!",
        en: "We're preparing something special for you!"
      }
    },
    buttons: {
      back: {
        ko: "뒤로 가기",
        en: "Go Back"
      },
      home: {
        ko: "홈으로",
        en: "Home"
      }
    }
  },
  projects: {
    title: {
      ko: "My Apps",
      en: "My Apps"
    },
    categories: {
      all: {
        ko: "전체",
        en: "All"
      },
      android: {
        ko: "Android",
        en: "Android"
      },
      ios: {
        ko: "iOS",
        en: "iOS"
      },
      macos: {
        ko: "MacOS",
        en: "MacOS"
      },
      flutter: {
        ko: "Flutter",
        en: "Flutter"
      },
      react: {
        ko: "React Native",
        en: "React Native"
      },
      dapp: {
        ko: "DApp",
        en: "DApp"
      }
    },
    projects: {
      englishWord: {
        title: {
          ko: "영어 단어 외우기",
          en: "English Vocabulary Builder"
        },
        description: {
          ko: "Swift와 SwiftUI를 사용한 MacOS 영단어 학습 앱",
          en: "A MacOS app for English vocabulary learning built with Swift and SwiftUI"
        }
      },
      comingSoon: {
        title: {
          ko: "준비중",
          en: "Coming Soon"
        },
        descriptions: {
          flutter: {
            ko: "Flutter 프로젝트 준비중입니다",
            en: "Flutter project in development"
          },
          android: {
            ko: "Android 프로젝트 준비중입니다",
            en: "Android project in development"
          },
          ios: {
            ko: "iOS 프로젝트 준비중입니다",
            en: "iOS project in development"
          },
          dapp: {
            ko: "DApp 프로젝트 준비중입니다",
            en: "DApp project in development"
          },
          macos: {
            ko: "MacOS 프로젝트 준비중입니다",
            en: "MacOS project in development"
          },
          react: {
            ko: "React Native 프로젝트 준비중입니다",
            en: "React Native project in development"
          }
        }
      }
    }
  },
  projectDetail: {
    preview: {
      ko: "앱 미리보기",
      en: "App Preview"
    },
    introduction: {
      ko: "앱 소개",
      en: "App Overview"
    },
    reviews: {
      count: {
        ko: "개의 평가",
        en: "reviews"
      }
    },
    downloadButtons: {
      appStore: {
        small: {
          ko: "다운로드",
          en: "Download on the"
        },
        large: {
          ko: "App Store",
          en: "App Store"
        }
      },
      playStore: {
        small: {
          ko: "다운로드",
          en: "GET IT ON"
        },
        large: {
          ko: "Google Play",
          en: "Google Play"
        }
      }
    },
    features: {
      title: {
        ko: "주요 기능",
        en: "Key Features"
      },
      list: [
        {
          ko: "영어 단어를 쉽게 외울 수 있다.",
          en: "Easy and intuitive vocabulary learning"
        },
        {
          ko: "영어 단어를 반복적으로 외울수 있다.",
          en: "Spaced repetition learning system"
        },
        {
          ko: "맞춤형 복습 시스템",
          en: "Personalized review schedule"
        }
      ]
    },
    techStack: {
      title: {
        ko: "사용된 기술",
        en: "Technologies Used"
      },
      items: [
        { name: "Flutter", icon: "flutter" },
        { name: "Dart", icon: "code" },
        { name: "Firebase", icon: "database" },
        { name: "Android Studio", icon: "tools" }
      ]
    },
    promotion: {
      ko: "새로운 업데이트! 가 소개될 예정 입니다.",
      en: "New update coming soon!"
    }
  }
};