import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Code, Globe, Github, Cpu, Terminal, Coffee } from 'lucide-react';
import { useState, useEffect } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../locales/translations';

const DashboardContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  padding-top: calc(70px + 2rem);

  @media (max-width: 768px) {
    padding: 1rem;
    padding-top: calc(70px + 1rem);
  }
`;

const DashboardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 3rem;
`;

const Title = styled(motion.h1)`
  font-size: 3.5rem;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  white-space: nowrap;
  background: linear-gradient(to right, ${props => props.theme.colors.accent}, #00a5ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  br {
    display: none;
  }

  @media (max-width: 768px) {
    font-size: 2.2rem;
    white-space: normal;

    br {
      display: block;
    }
  }
`;

const DashboardSubtitle = styled(motion.p)`
  font-size: 1.25rem;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0 1rem;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

const Card = styled(motion.div)`
  background: ${props => props.theme.colors.card.background};
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  padding: 1.5rem;
  border: ${props => props.theme.colors.card.border};
  box-shadow: ${props => props.theme.colors.card.boxShadow};
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  @media (max-width: 768px) {
    padding: 1.2rem;
  }

  &:hover {
    box-shadow: ${props => props.theme.colors.card.hover.boxShadow};
    transform: ${props => props.theme.colors.card.hover.transform};
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    margin-bottom: 0.8rem;
  }
`;

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 0.95rem;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const ProgressBar = styled.div`
  height: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 999px;
  overflow: hidden;
`;

const Progress = styled.div`
  height: 100%;
  background: ${props => props.theme.colors.accent};
  border-radius: 999px;
  transition: width 0.3s ease;
  width: ${props => props.value}%;
`;

const StatusGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media (max-width: 768px) {
    gap: 0.8rem;
  }
`;

const StatusItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const StatusDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${props => props.theme.colors.accent};
`;

const Home = () => {
  const [codeLines, setCodeLines] = useState(0);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const { language } = useLanguage();
  const t = translations.home;
  
  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 769px)');
    
    const handleResize = (e) => {
      setIsDesktop(e.matches);
    };
    
    mediaQuery.addListener(handleResize);
    setIsDesktop(mediaQuery.matches);

    return () => mediaQuery.removeListener(handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCodeLines(prev => {
        if (prev >= 99) {
          clearInterval(interval);
          return 99;
        }
        return prev + 1;
      });
    }, 70);

    return () => clearInterval(interval);
  }, []);

  return (
    <DashboardContainer>
      <DashboardHeader>
        <Title
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {language === 'ko' ? (
            isDesktop ? (
              <span>{t.title.ko.first}</span>
            ) : (
              <>
                <span>{t.title.ko.second}</span>
                <br />
                <span>{t.title.ko.third}</span>
              </>
            )
          ) : (
            t.title.en
          )}
        </Title>
        <DashboardSubtitle
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {t.subtitle[language]}
        </DashboardSubtitle>
      </DashboardHeader>

      <GridContainer>
        <Card
          whileHover={{ y: -5 }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <CardHeader>
            <CardTitle>
              <Code size={20} color="#0071e3" />
              <span>{t.cards.stack.title[language]}</span>
            </CardTitle>
            <Terminal size={16} color="#10B981" />
          </CardHeader>
          <StatusGrid>
            <StatusItem>
              <StatusDot />
              <span>React</span>
            </StatusItem>
            <StatusItem>
              <StatusDot />
              <span>Flutter</span>
            </StatusItem>
            <StatusItem>
              <StatusDot />
              <span>Swift</span>
            </StatusItem>
            <StatusItem>
              <StatusDot />
              <span>Rust</span>
            </StatusItem>
          </StatusGrid>
        </Card>

        <Card
          whileHover={{ y: -5 }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <CardHeader>
            <CardTitle>
              <Globe size={20} color="#8B5CF6" />
              <span>{t.cards.projects.title[language]}</span>
            </CardTitle>
            <Github size={20} color="#10B981" />
          </CardHeader>
          <ProgressContainer>
            <ProgressBar>
              <Progress value={(codeLines / 99) * 100} />
            </ProgressBar>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>{t.stats.codeLines[language]}</span>
              <span>{codeLines.toLocaleString()} {t.stats.unit[language]}</span>
            </div>
          </ProgressContainer>
          <StatusGrid style={{ marginTop: '1rem' }}>
            <StatusItem>
              <StatusDot />
              <span>{t.stats.projects[language]}</span>
            </StatusItem>
            <StatusItem>
              <StatusDot />
              <span>{t.stats.deployments[language]}</span>
            </StatusItem>
          </StatusGrid>
        </Card>
      </GridContainer>

      <GridContainer>
        <Card
          whileHover={{ y: -5 }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <CardHeader>
            <CardTitle>
              <Coffee size={20} color="#F59E0B" />
              <span>{t.cards.recent.title[language]}</span>
            </CardTitle>
          </CardHeader>
          <StatusGrid>
            {t.cards.recent.items.map((item, index) => (
              <StatusItem key={index} style={{ gridColumn: '1 / -1' }}>
                <StatusDot />
                <span>{item[language]}</span>
              </StatusItem>
            ))}
          </StatusGrid>
        </Card>

        <Card
          whileHover={{ y: -5 }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <CardHeader>
            <CardTitle>
              <Cpu size={20} color="#EC4899" />
              <span>{t.cards.skills.title[language]}</span>
            </CardTitle>
          </CardHeader>
          <StatusGrid>
            {Object.entries(t.cards.skills.items).map(([key, value]) => (
              <StatusItem key={key}>
                <StatusDot />
                <span>{value}</span>
              </StatusItem>
            ))}
          </StatusGrid>
        </Card>
      </GridContainer>
    </DashboardContainer>
  );
};

export default Home; 