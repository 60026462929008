import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Container, GradientText } from '../styles/commonStyles';
import { Mail, Github, Linkedin, Twitter, ChevronDown, Copy, Check, Briefcase, Users, HelpCircle, CalendarCheck, Smartphone, MessageCircle, MessageSquare } from 'lucide-react';
import { useLanguage } from '../contexts/LanguageContext';

const ContactContainer = styled(Container)`
  padding-top: calc(70px + 4rem);
  min-height: 100vh;
  max-width: 1200px;
  padding: calc(70px + 4rem) 2rem 4rem;

  @media (max-width: 768px) {
    padding: calc(70px + 2rem) 1rem 2rem;
  }
`;

const ContactHeader = styled.div`
  text-align: center;
  margin-bottom: 8rem;
  max-width: 800px;
  margin: 0 auto 8rem;

  @media (max-width: 768px) {
    margin-bottom: 3rem;
    max-width: 90%;
  }

  @media (max-width: 480px) {
    max-width: 95%;
  }
`;

const Title = styled(motion.h1)`
  font-size: 3.5rem;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 0.8rem;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

const Subtitle = styled(motion.p)`
  color: ${props => props.theme.colors.secondary};
  font-size: 1.3rem;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.8;
  
  span {
    display: block;
    margin-top: 0.5rem;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 0 1.5rem;
    line-height: 1.4;
    
    span {
      margin-top: 0.3rem;
    }
  }

  @media (max-width: 480px) {
    font-size: 0.85rem;
    padding: 0 1rem;
    
    span {
      margin-top: 0.5rem;
    }
  }
`;

const EmailSection = styled.section`
  text-align: center;
  margin-bottom: 8rem;

  @media (max-width: 768px) {
    margin-bottom: 3rem;
    padding: 0 1rem;
  }
`;

const EmailContainer = styled(motion.div)`
  display: inline-flex;
  align-items: center;
  gap: 1.5rem;
  padding: 1.5rem 2rem;
  background: ${props => props.theme.colors.card.background};
  border-radius: 12px;
  border: ${props => props.theme.colors.card.border};
  box-shadow: ${props => props.theme.colors.card.boxShadow};
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 300px;
    padding: 1rem;
    gap: 0.6rem;
  }

  svg {
    color: ${props => props.theme.colors.accent};
    width: 24px;
    height: 24px;

    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }
  }
`;

const EmailContent = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    gap: 0.8rem;
    width: 100%;
    justify-content: center;
  }

  a {
    font-size: 1.2rem;
    font-weight: 500;
    color: ${props => props.theme.colors.text};
    transition: all 0.3s ease;

    &:hover {
      color: ${props => props.theme.colors.accent};
    }

    @media (max-width: 768px) {
      font-size: 0.95rem;
    }
  }
`;

const CopyButton = styled.button`
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  color: ${props => props.theme.colors.secondary};
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    padding: 0.4rem;
  }

  &:hover {
    color: ${props => props.theme.colors.accent};
  }

  svg {
    width: 20px;
    height: 20px;

    @media (max-width: 768px) {
      width: 18px;
      height: 18px;
    }
  }
`;

const CategoryGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 768px) {
    gap: 0.8rem;
  }
`;

const Category = styled(motion.div)`
  cursor: pointer;
  padding: 1.5rem;
  border-radius: 12px;
  background: ${props => props.theme.colors.card.background};
  border: ${props => props.theme.colors.card.border};

  @media (max-width: 768px) {
    padding: 1rem;

    h3 {
      font-size: 1rem;
    }

    p {
      font-size: 0.9rem;
      line-height: 1.5;
    }
  }

  h3 {
    font-size: 1.2rem;
    color: ${props => props.theme.colors.accent};
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      font-size: 1.1rem;
    }
  }

  p {
    color: ${props => props.theme.colors.secondary};
    line-height: 1.6;

    @media (max-width: 768px) {
      font-size: 0.95rem;
    }
  }
`;

const CategoryTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  svg {
    color: ${props => props.theme.colors.accent};
    width: 24px;
    height: 24px;
  }
`;

const Description = styled.p`
  color: ${props => props.theme.colors.secondary};
  line-height: 1.8;
  font-size: 1.1rem;
  margin-bottom: ${props => props.$isOpen ? '1.5rem' : '0'};
`;

const DetailList = styled(motion.div)`
  border-top: 1px solid ${props => props.theme.colors.border};
  margin-top: 1rem;
  padding-top: 1.5rem;

  @media (max-width: 768px) {
    margin-top: 0.8rem;
    padding-top: 1rem;

    h4 {
      font-size: 0.95rem;
      margin-bottom: 0.6rem;
    }

    li {
      font-size: 0.85rem;
      line-height: 1.4;
      margin-bottom: 0.4rem;
    }
  }
`;

const DetailItem = styled.div`
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
  }

  h4 {
    color: ${props => props.theme.colors.text};
    font-size: 1.1rem;
    margin-bottom: 1rem;
    font-weight: 500;

    @media (max-width: 768px) {
      font-size: 1rem;
      margin-bottom: 0.8rem;
    }
  }

  ul {
    list-style: none;
    padding-left: 1.2rem;
  }

  li {
    color: ${props => props.theme.colors.secondary};
    line-height: 1.8;
    position: relative;
    margin-bottom: 0.5rem;
    font-size: 0.95rem;

    @media (max-width: 768px) {
      font-size: 0.9rem;
      line-height: 1.6;
    }

    &::before {
      content: '';
      position: absolute;
      left: -1.2rem;
      top: 0.8rem;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: ${props => props.theme.colors.accent};
    }
  }
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-top: 8rem;

  @media (max-width: 768px) {
    gap: 1.5rem;
    margin-top: 3rem;
  }

  svg {
    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }
  }
`;

const SocialLink = styled.a`
  color: ${props => props.theme.colors.secondary};
  transition: all 0.3s ease;
  padding: 1rem;
  border-radius: 50%;
  
  @media (max-width: 768px) {
    padding: 0.6rem;
  }

  &:hover {
    color: ${props => props.theme.colors.accent};
    transform: translateY(-3px);
    background: ${props => props.theme.colors.cardHover};
  }

  svg {
    width: 32px;
    height: 32px;

    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.colors.border};
  margin: 2rem 0;

  @media (max-width: 768px) {
    margin: 1.5rem 0;
  }
`;

const Contact = () => {
  const { language } = useLanguage();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [copied, setCopied] = useState(false);
  const email = "info@cemardz.com";

  // 하드코딩된 번역 데이터
  const t = {
    subtitle: {
      line1: language === 'ko' 
        ? "프로젝트 협업, 채용 문의, 기술 관련 질문 등" 
        : "Project collaboration, recruitment inquiries, technical questions",
      line2: language === 'ko'
        ? "언제든 편하게 연락해주세요."
        : "Feel free to reach out anytime."
    },
    email: {
      copy: language === 'ko' ? "이메일 복사" : "Copy email"
    }
  };

  // 카테고리 데이터
  const categories = [
    {
      title: language === 'ko' ? "프로젝트 협업" : "Project Collaboration",
      icon: <Briefcase />,
      description: language === 'ko' 
        ? "새로운 프로젝트나 협업 제안을 환영합니다."
        : "Welcome to discuss new projects or collaboration opportunities.",
      details: [
        {
          title: language === 'ko' ? "협업 분야" : "Collaboration Areas",
          items: language === 'ko' 
            ? ["모바일 앱 개발", "웹 개발", "블록체인 개발"]
            : ["Mobile App Development", "Web Development", "Blockchain Development"]
        }
      ]
    },
    {
      title: language === 'ko' ? "채용 문의" : "Recruitment",
      icon: <Users />,
      description: language === 'ko'
        ? "새로운 기회를 찾고 있습니다."
        : "Open to new opportunities.",
      details: [
        {
          title: language === 'ko' ? "관심 분야" : "Areas of Interest",
          items: language === 'ko'
            ? ["프론트엔드 개발", "모바일 앱 개발", "블록체인 개발"]
            : ["Frontend Development", "Mobile App Development", "Blockchain Development"]
        }
      ]
    },
    {
      title: language === 'ko' ? "미팅 요청" : "Meeting Request",
      icon: <CalendarCheck />,
      description: language === 'ko'
        ? "온/오프라인 미팅을 환영합니다."
        : "Welcome both online and offline meetings.",
      details: [
        {
          title: language === 'ko' ? "미팅 주제" : "Meeting Topics",
          items: language === 'ko'
            ? ["기술 토론", "프로젝트 논의", "커리어 상담"]
            : ["Technical Discussion", "Project Discussion", "Career Consultation"]
        }
      ]
    },
    {
      title: language === 'ko' ? "앱 문의" : "App Inquiry",
      icon: <MessageCircle />,
      description: language === 'ko'
        ? "앱 사용에 대한 문의를 환영합니다."
        : "Welcome inquiries about app usage.",
      details: [
        {
          title: language === 'ko' ? "문의 사항" : "Inquiry Topics",
          items: language === 'ko'
            ? ["사용 방법", "기능 설명", "구매 문의"]
            : ["How to Use", "Feature Explanation", "Purchase Inquiry"]
        }
      ]
    },
    {
      title: language === 'ko' ? "앱 피드백" : "App Feedback",
      icon: <MessageSquare />,
      description: language === 'ko'
        ? "앱 개선을 위한 의견을 기다립니다."
        : "Looking forward to your feedback for app improvement.",
      details: [
        {
          title: language === 'ko' ? "피드백 분야" : "Feedback Areas",
          items: language === 'ko'
            ? ["UI/UX", "기능 개선", "버그 리포트"]
            : ["UI/UX", "Feature Enhancement", "Bug Report"]
        }
      ]
    },
    {
      title: language === 'ko' ? "기술 문의" : "Technical Inquiry",
      icon: <HelpCircle />,
      description: language === 'ko'
        ? "기술 관련 질문을 환영합니다."
        : "Welcome technical questions.",
      details: [
        {
          title: language === 'ko' ? "문의 가능 분야" : "Available Topics",
          items: language === 'ko'
            ? ["앱 개발", "웹 개발", "블록체인"]
            : ["App Development", "Web Development", "Blockchain"]
        }
      ]
    }
  ];

  useEffect(() => {
    document.title = 'Contact - CEMARDZ';
    return () => {
      document.title = 'CEMARDZ';
    };
  }, []);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(email);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <ContactContainer>
      <ContactHeader>
        <Title
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Let's <GradientText>Connect</GradientText>
        </Title>
        <Subtitle
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {t.subtitle.line1}
          <span>{t.subtitle.line2}</span>
        </Subtitle>
      </ContactHeader>

      <EmailSection>
        <EmailContainer>
          <Mail />
          <EmailContent>
            <a href={`mailto:${email}`}>{email}</a>
            <CopyButton onClick={handleCopy} aria-label={t.email.copy}>
              {copied ? <Check /> : <Copy />}
            </CopyButton>
          </EmailContent>
        </EmailContainer>
      </EmailSection>

      <CategoryGrid>
        {categories.slice(0, 3).map((category, index) => (
          <Category
            key={index}
            onClick={() => setSelectedCategory(selectedCategory === index ? null : index)}
          >
            <CategoryTitle>
              {category.icon}
              <h3>{category.title}</h3>
              <motion.div
                animate={{ rotate: selectedCategory === index ? 180 : 0 }}
                transition={{ duration: 0.3 }}
              >
                <ChevronDown size={20} />
              </motion.div>
            </CategoryTitle>
            <Description $isOpen={selectedCategory === index}>
              {category.description}
            </Description>
            <AnimatePresence>
              {selectedCategory === index && (
                <DetailList
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  {category.details.map((section, i) => (
                    <DetailItem key={i}>
                      <h4>{section.title}</h4>
                      <ul>
                        {section.items.map((item, j) => (
                          <li key={j}>{item}</li>
                        ))}
                      </ul>
                    </DetailItem>
                  ))}
                </DetailList>
              )}
            </AnimatePresence>
          </Category>
        ))}
        <Divider />
        {categories.slice(3).map((category, index) => (
          <Category
            key={index + 3}
            onClick={() => setSelectedCategory(selectedCategory === index + 3 ? null : index + 3)}
          >
            <CategoryTitle>
              {category.icon}
              <h3>{category.title}</h3>
              <motion.div
                animate={{ rotate: selectedCategory === index + 3 ? 180 : 0 }}
                transition={{ duration: 0.3 }}
              >
                <ChevronDown size={20} />
              </motion.div>
            </CategoryTitle>
            <Description $isOpen={selectedCategory === index + 3}>
              {category.description}
            </Description>
            <AnimatePresence>
              {selectedCategory === index + 3 && (
                <DetailList
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  {category.details.map((section, i) => (
                    <DetailItem key={i}>
                      <h4>{section.title}</h4>
                      <ul>
                        {section.items.map((item, j) => (
                          <li key={j}>{item}</li>
                        ))}
                      </ul>
                    </DetailItem>
                  ))}
                </DetailList>
              )}
            </AnimatePresence>
          </Category>
        ))}
      </CategoryGrid>
    </ContactContainer>
  );
};

export default Contact;