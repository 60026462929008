export const darkTheme = {
  colors: {
    background: '#000000',
    text: '#ffffff',
    accent: '#0071e3',
    secondary: '#8e8e93',
    tertiary: '#1c1c1e',
    card: {
      background: 'rgba(255, 255, 255, 0.07)',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
      hover: {
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
        transform: 'translateY(-2px)'
      }
    },
    border: 'rgba(255, 255, 255, 0.1)',
    gradient: 'linear-gradient(145deg, #000000, #1a1a1a)',
    nav: 'rgba(28, 28, 30, 0.8)',
    footer: '#1c1c1e',
    cardHover: 'rgba(255, 255, 255, 0.1)',
    shadow: 'rgba(0, 0, 0, 0.3)'
  }
}; 