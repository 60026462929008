import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { Sun, Moon, Globe, Menu, X, Home, User, FolderOpen, Mail, ChevronRight } from 'lucide-react';
import { useLanguage } from '../../contexts/LanguageContext';
import { translations } from '../../locales/translations';
import { useState, useEffect } from 'react';
import { useTheme } from '../../contexts/ThemeContext';

const NavContainer = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--nav-height, 60px);
  background: ${props => `rgba(${props.theme.colors.backgroundRGB}, 0.98)`};
  backdrop-filter: blur(12px);
  border-bottom: 1px solid ${props => `rgba(${props.theme.colors.textRGB}, 0.1)`};
  z-index: 1002;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
`;

const NavContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;

  @media (max-width: 768px) {
    padding: 0 1rem;
    gap: 1rem;
  }
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  
  img {
    height: 40px;
    width: auto;
    transition: filter 0.3s ease;
  }

  &:hover {
    img {
      filter: ${props => props.theme.colors.background === '#ffffff' 
        ? 'brightness(0) saturate(100%) invert(31%) sepia(98%) saturate(1946%) hue-rotate(197deg) brightness(97%) contrast(101%)'
        : 'brightness(0) saturate(100%) invert(31%) sepia(98%) saturate(1946%) hue-rotate(197deg) brightness(97%) contrast(101%)'};
    }
  }

  @media (max-width: 768px) {
    img {
      height: 32px;
    }
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  color: ${props => props.theme.colors.text};
  transition: color 0.15s ease;
  
  &:hover {
    color: ${props => props.theme.colors.accent};
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
    width: 100%;
    text-align: center;
    padding: 1.5rem;
    border-radius: 8px;
    
    &:hover {
      background: ${props => props.theme.colors.cardHover};
    }
  }
`;

const NavLinkDescription = styled.span`
  display: none;
  font-size: 0.8rem;
  color: ${props => props.theme.colors.secondary};
  
  @media (max-width: 768px) {
    display: block;
  }
`;

const MobileControls = styled.div`
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid ${props => `rgba(${props.theme.colors.textRGB}, 0.1)`};
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MobileControlButton = styled.button`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1.2rem;
  background: ${props => `rgba(${props.theme.colors.backgroundRGB}, 0.7)`};
  backdrop-filter: blur(8px);
  border: 1px solid ${props => `rgba(${props.theme.colors.textRGB}, 0.1)`};
  border-radius: 12px;
  color: ${props => props.theme.colors.text};
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    transform: translateY(-2px);
    background: ${props => `rgba(${props.theme.colors.backgroundRGB}, 0.8)`};
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const NavControls = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    display: flex;
    margin-left: auto;
  }
`;

const ThemeToggle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background: none;
  border: none;
  color: ${props => props.theme.colors.text};
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: ${props => props.theme.colors.accent};
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

const LanguageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background: none;
  border: none;
  color: ${props => props.theme.colors.text};
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: ${props => props.theme.colors.accent};
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: ${props => props.theme.colors.text};
  cursor: pointer;
  padding: 0.5rem;
  transition: all 0.3s ease;
  
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    color: ${props => props.theme.colors.accent};
  }
`;

const MobileNav = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: block;
    position: fixed;
    top: var(--nav-height, 60px);
    left: 0;
    width: 100%;
    height: calc(100vh - var(--nav-height, 60px));
    background: ${props => `rgba(${props.theme.colors.backgroundRGB}, 0.95)`};
    backdrop-filter: blur(10px);
    padding: 2rem 1.5rem;
    transform: translateY(${props => props.$isOpen ? '0' : '-110%'});
    opacity: ${props => props.$isOpen ? 1 : 0};
    visibility: ${props => props.$isOpen ? 'visible' : 'hidden'};
    transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    overflow-y: auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid ${props => `rgba(${props.theme.colors.textRGB}, 0.1)`};
    z-index: 1001;
  }
`;

const MobileOverlay = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: ${props => props.theme.colors.background};
    opacity: ${props => props.$isOpen ? 0.85 : 0};
    visibility: ${props => props.$isOpen ? 'visible' : 'hidden'};
    backdrop-filter: blur(10px);
    transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 1000;
  }
`;

const MobileMenuItem = styled.div`
  margin-bottom: 1rem;
  border-radius: 12px;
  background: ${props => `rgba(${props.theme.colors.backgroundRGB}, 0.7)`};
  backdrop-filter: blur(8px);
  border: 1px solid ${props => `rgba(${props.theme.colors.textRGB}, 0.1)`};
  overflow: hidden;
  opacity: 0;
  transform: translateY(20px);
  animation: ${props => props.$isOpen ? 'slideIn 0.6s forwards' : 'none'};
  animation-delay: ${props => props.$index * 0.1}s;
  transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &:hover {
    transform: translateY(-2px);
    background: ${props => `rgba(${props.theme.colors.backgroundRGB}, 0.8)`};
  }
`;

const MobileMenuLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem;
  color: ${props => props.theme.colors.text};
  transition: all 0.3s ease;
  
  &:hover {
    background: ${props => `rgba(${props.theme.colors.textRGB}, 0.05)`};
  }
`;

const MenuLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const MenuText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  
  span {
    font-weight: 500;
  }
  
  small {
    font-size: 0.8rem;
    color: ${props => props.theme.colors.secondary};
  }
`;

const MenuArrow = styled.div`
  svg {
    width: 16px;
    height: 16px;
    color: ${props => props.theme.colors.secondary};
  }
`;

const Navbar = () => {
  const { language, toggleLanguage } = useLanguage();
  const t = translations.nav;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isDarkMode, toggleTheme } = useTheme();

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpen]);

  const location = useLocation();
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  return (
    <>
      <MobileOverlay $isOpen={isMenuOpen} />
      <NavContainer>
        <NavContent>
          <Logo to="/">
            <img 
              src={isDarkMode ? '/dr.png' : '/li.png'} 
              alt="Logo" 
              height="40"
            />
          </Logo>
          <NavLinks>
            <NavLink to="/">
              {t.home[language]}
            </NavLink>
            <NavLink to="/about">
              {t.about[language]}
            </NavLink>
            <NavLink to="/projects">
              {t.projects[language]}
            </NavLink>
            <NavLink to="/contact">
              {t.contact[language]}
            </NavLink>
          </NavLinks>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <NavControls>
              <LanguageButton onClick={toggleLanguage} aria-label={t.language}>
                <Globe />
              </LanguageButton>
              <ThemeToggle onClick={toggleTheme} aria-label={t.theme}>
                {isDarkMode ? <Sun /> : <Moon />}
              </ThemeToggle>
            </NavControls>
            <MenuButton 
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-label="Toggle menu"
            >
              {isMenuOpen ? <X /> : <Menu />}
            </MenuButton>
          </div>
        </NavContent>
      </NavContainer>
      <MobileNav $isOpen={isMenuOpen}>
        {[
          { to: '/', icon: <Home />, title: t.home[language], desc: t.homeDesc },
          { to: '/about', icon: <User />, title: t.about[language], desc: t.aboutDesc },
          { to: '/projects', icon: <FolderOpen />, title: t.projects[language], desc: t.projectsDesc },
          { to: '/contact', icon: <Mail />, title: t.contact[language], desc: t.contactDesc }
        ].map((item, index) => (
          <MobileMenuItem key={index} $isOpen={isMenuOpen} $index={index}>
            <MobileMenuLink to={item.to} onClick={() => setIsMenuOpen(false)}>
              <MenuLeft>
                {item.icon}
                <MenuText>
                  <span>{item.title}</span>
                  <small>{item.desc}</small>
                </MenuText>
              </MenuLeft>
              <MenuArrow>
                <ChevronRight />
              </MenuArrow>
            </MobileMenuLink>
          </MobileMenuItem>
        ))}
      </MobileNav>
    </>
  );
};

export default Navbar; 