import { createContext, useContext, useState, useEffect } from 'react';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    // 1. localStorage에서 확인
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      return savedLanguage;
    }
    
    // 2. 브라우저 언어 설정 확인
    const browserLang = navigator.language || navigator.userLanguage;
    if (browserLang.startsWith('ko')) {
      return 'ko';
    }
    if (browserLang.startsWith('en')) {
      return 'en';
    }
    
    return 'ko'; // 기본값
  });

  // 언어 설정 변경 감지
  useEffect(() => {
    const handleLanguageChange = () => {
      const browserLang = navigator.language || navigator.userLanguage;
      if (!localStorage.getItem('language')) {  // 사용자가 수동으로 설정하지 않은 경우에만
        if (browserLang.startsWith('ko')) {
          setLanguage('ko');
        } else if (browserLang.startsWith('en')) {
          setLanguage('en');
        }
      }
    };

    window.addEventListener('languagechange', handleLanguageChange);
    return () => window.removeEventListener('languagechange', handleLanguageChange);
  }, []);

  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);

  const toggleLanguage = () => {
    setLanguage(prev => prev === 'ko' ? 'en' : 'ko');
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}; 