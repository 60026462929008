import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Card, Container, GradientText } from '../styles/commonStyles';
import { Code, Coffee, Globe, Heart, Lightbulb, Users, Target, Rocket, Shield } from 'lucide-react';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../locales/translations';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

const AboutContainer = styled(Container)`
  padding-top: calc(70px + 2rem);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding-top: calc(70px + 1rem);
  }
`;

const AboutHeader = styled.div`
  text-align: center;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }
`;

const Title = styled(motion.h1)`
  font-size: 3rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }
`;

const Subtitle = styled(motion.p)`
  color: ${props => props.theme.colors.secondary};
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.8;

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0 1rem;
  }
`;

const GridSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    margin-bottom: 2rem;
    padding: 0 1rem;
  }
`;

const AboutCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  min-height: 200px;
  padding: 2rem;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    min-height: auto;
    padding: 1.5rem;
  }
`;

const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background: ${props => props.theme.colors.accent};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const CardTitle = styled.h3`
  font-size: 1.3rem;
  color: ${props => props.theme.colors.text};
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const CardContent = styled.p`
  color: ${props => props.theme.colors.secondary};
  font-size: 0.95rem;
  line-height: 1.6;
  flex-grow: 1;
`;

const DonationLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 2rem;
  padding: 0.8rem 1.5rem;
  background: ${props => props.theme.colors.card.background};
  border: ${props => props.theme.colors.card.border};
  border-radius: 20px;
  color: ${props => props.theme.colors.text};
  font-size: 0.9rem;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    background: ${props => props.theme.colors.cardHover};
  }

  svg {
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: translateX(3px);
  }

  @media (max-width: 768px) {
    padding: 0.6rem 1.2rem;
    font-size: 0.8rem;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  margin-top: 2rem;
`;

const About = () => {
  const { language } = useLanguage();
  const t = translations.about;

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 }
  };

  const introCards = [
    {
      icon: <Lightbulb size={24} />,
      ...t.intro.cemardz,
    },
    {
      icon: <Target size={24} />,
      ...t.intro.experience,
    },
    {
      icon: <Rocket size={24} />,
      ...t.intro.passion,
    }
  ];

  const philosophyCards = [
    {
      icon: <Code size={24} />,
      ...t.philosophy.development,
    },
    {
      icon: <Shield size={24} />,
      ...t.philosophy.ethicalResponsibility,
    },
    {
      icon: <Coffee size={24} />,
      ...t.philosophy.valueOfKnowledgeSharing,
    },
    {
      icon: <Heart size={24} />,
      ...t.philosophy.transparencyAndTrust,
    },
    {
      icon: <Globe size={24} />,
      ...t.philosophy.contributingToABetterWorld,
    },
    {
      icon: <Users size={24} />,
      ...t.philosophy.flexibilityAndInnovation,
    }
  ];

  useEffect(() => {
    document.title = `About - CEMARDZ`;
    return () => {
      document.title = 'CEMARDZ';
    };
  }, []);

  return (
    <AboutContainer>
      <AboutHeader>
        <Title>
          About <GradientText>Me</GradientText>
        </Title>
        <Subtitle>
          {t.subtitle[language]}
        </Subtitle>
      </AboutHeader>

      <GridSection>
        {introCards.map((card, index) => (
          <AboutCard key={index} {...fadeInUp} transition={{ duration: 0.5, delay: index * 0.2 }}>
            <IconWrapper>{card.icon}</IconWrapper>
            <CardTitle>{card.title[language]}</CardTitle>
            <CardContent>{card.content[language]}</CardContent>
          </AboutCard>
        ))}
      </GridSection>

      <GridSection>
        {philosophyCards.map((card, index) => (
          <AboutCard key={index} {...fadeInUp} transition={{ duration: 0.5, delay: index * 0.1 }}>
            <IconWrapper>{card.icon}</IconWrapper>
            <CardTitle>{card.title[language]}</CardTitle>
            <CardContent>{card.content[language]}</CardContent>
          </AboutCard>
        ))}
      </GridSection>

      <Footer>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <DonationLink to="/donation">
            {language === 'ko' ? '기타' : 'ETC'}
            <ChevronRight />
          </DonationLink>
        </motion.div>
      </Footer>
    </AboutContainer>
  );
};

export default About; 