import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Container, GradientText } from '../styles/commonStyles';
import { Github, ExternalLink, Tag } from 'lucide-react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { useTheme } from '../contexts/ThemeContext';

const ProjectsContainer = styled(Container)`
  padding-top: calc(70px + 2rem);
  padding-bottom: calc(70px + 2rem);
  min-height: 100vh;

  @media (max-width: 768px) {
    padding-top: calc(70px + 1rem);
    padding-bottom: calc(70px + 1rem);
  }
`;

const ProjectsHeader = styled.div`
  text-align: center;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }
`;

const Title = styled(motion.h1)`
  font-size: 3rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    gap: 0.5rem;
    margin-bottom: 2rem;
    padding: 0 0.5rem;
  }
`;

const FilterButton = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 20px;
  background: ${props => props.$active ? props.theme.colors.accent : 'transparent'};
  color: ${props => props.$active ? 'white' : props.theme.colors.text};
  border: 1px solid ${props => props.theme.colors.accent};
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.95rem;

  @media (max-width: 768px) {
    padding: 0.4rem 0.8rem;
    font-size: 0.85rem;
  }
`;

const ProjectGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

const ProjectCard = styled(motion.div)`
  background: ${props => props.theme.colors.background === '#ffffff' 
    ? '#f5f5f7' 
    : props.theme.colors.card.background};
  border-radius: 1rem;
  overflow: hidden;
  border: ${props => props.theme.colors.card.border};
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  aspect-ratio: 3/4;

  @media (max-width: 768px) {
    aspect-ratio: 3/4;
  }
`;

const ProjectImageWrapper = styled.div`
  width: 100%;
  flex: 2;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.tertiary};
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const ProjectContent = styled.div`
  padding: 1.5rem;
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const ProjectTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.text};

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }
`;

const ProjectDescription = styled.p`
  color: ${props => props.theme.colors.secondary};
  margin-bottom: auto;
  line-height: 1.6;
  flex: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const TechStack = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-top: auto;

  @media (max-width: 768px) {
    gap: 0.4rem;
  }
`;

const TechTag = styled.span`
  padding: 0.25rem 0.75rem;
  border-radius: 15px;
  background: ${props => props.theme.colors.background === '#ffffff' 
    ? '#ffffff' 
    : props.theme.colors.tertiary};
  color: ${props => props.theme.colors.text};
  font-size: 0.875rem;

  @media (max-width: 768px) {
    padding: 0.2rem 0.6rem;
    font-size: 0.8rem;
  }
`;

const ProjectLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: flex-end;

  @media (max-width: 768px) {
    gap: 0.8rem;
    margin-top: 0.8rem;
  }
`;

const ProjectLink = styled.div`
  color: ${props => props.theme.colors.secondary};
  transition: color 0.2s ease;
  opacity: ${props => props.$disabled ? 0.5 : 1};
  cursor: ${props => props.$disabled ? 'not-allowed' : 'pointer'};
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: ${props => props.$disabled ? props.theme.colors.secondary : props.theme.colors.accent};
  }
  
  svg, i {
    width: 20px;
    height: 20px;
    font-size: 20px;

    @media (max-width: 768px) {
      width: 18px;
      height: 18px;
      font-size: 18px;
    }
  }
`;

// stagger 애니메이션을 위한 variants 추가
const containerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
      delayChildren: 0.2
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  show: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut"
    }
  }
};

const Projects = () => {
  const [filter, setFilter] = useState('all');
  const [isFiltering, setIsFiltering] = useState(false);
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const { language } = useLanguage();
  
  // translations 오류 해결을 위해 하드코딩된 데이터 사용
  const t = {
    categories: {
      all: language === 'ko' ? "전체" : "All",
      android: "Android",
      ios: "iOS",
      macos: "MacOS",
      flutter: "Flutter",
      react: "React Native",
      dapp: "DApp"
    },
    projects: {
      englishWord: {
        title: language === 'ko' ? "영어 단어 외우기" : "English Vocabulary Builder",
        description: language === 'ko' 
          ? "Swift와 SwiftUI를 사용한 MacOS 영단어 학습 앱"
          : "A MacOS app for English vocabulary learning built with Swift and SwiftUI"
      },
      comingSoon: {
        title: language === 'ko' ? "준비중" : "Coming Soon",
        descriptions: {
          flutter: language === 'ko' ? "Flutter 프로젝트 준비중입니다" : "Flutter project in development",
          android: language === 'ko' ? "Android 프로젝트 준비중입니다" : "Android project in development",
          ios: language === 'ko' ? "iOS 프로젝트 준비중입니다" : "iOS project in development"
        }
      }
    }
  };

  useEffect(() => {
    document.title = 'Projects - CEMARDZ';
    return () => {
      document.title = 'CEMARDZ';
    };
  }, []);

  // 언어와 테마에 따른 이미지 선택 함수
  const getComingSoonImage = () => {
    if (language === 'ko') {
      return isDarkMode ? '/images/pr-kr-d.jpeg' : '/images/pr-kr-w.jpeg';
    } else {
      return isDarkMode ? '/images/pr-en-d.jpeg' : '/images/pr-en-w.jpeg';
    }
  };

  const projects = [
    {
      id: 1,
      slug: 'english',
      title: t.projects.englishWord.title,
      description: t.projects.englishWord.description,
      image: getComingSoonImage(),
      tech: ["Flutter", "Dart", "Firebase"],
      github: "https://github.com/pstor-eon",
      playstore: "#",
      appstore: "#",
      category: "flutter"
    },
    // Flutter 더미 데이터
    {
      id: 'coming-soon-flutter',
      slug: 'coming-soon-flutter',
      title: t.projects.comingSoon.title,
      description: t.projects.comingSoon.descriptions.flutter,
      image: getComingSoonImage(),
      tech: ["Flutter", "Dart"],
      category: "flutter",
      github: "#",
      playstore: "#",
      appstore: "#"
    },
    // Android 더미 데이터
    {
      id: 'coming-soon-android-1',
      title: t.projects.comingSoon.title,
      description: t.projects.comingSoon.descriptions.android,
      image: getComingSoonImage(),
      tech: ["Android", "Kotlin"],
      category: "android",
      github: "#",
      playstore: "#",
      appstore: "#"
    },
    // iOS 더미 데이터
    {
      id: 'coming-soon-ios-1',
      title: t.projects.comingSoon.title,
      description: t.projects.comingSoon.descriptions.ios,
      image: getComingSoonImage(),
      tech: ["iOS", "Swift"],
      category: "ios",
      github: "#",
      playstore: "#",
      appstore: "#"
    },
    // MacOS 더미 데이터
    // React Native 더미 데이터
  ];

  const filteredProjects = filter === 'all' 
    ? projects 
    : projects.filter(project => project.category === filter);

  // 필터 변경 시 애니메이션 처리
  const handleFilterChange = (newFilter) => {
    setIsFiltering(true);  // 필터링 시작
    setFilter(newFilter);
    setTimeout(() => {
      setIsFiltering(false);  // 필터링 종료
    }, 100);  // 짧은 지연 시간 추가
  };

  return (
    <ProjectsContainer>
      <ProjectsHeader>
        <Title>
          My <GradientText>Apps</GradientText>
        </Title>
      </ProjectsHeader>

      <FilterContainer>
        <FilterButton 
          $active={filter === 'all'} 
          onClick={() => handleFilterChange('all')}
        >
          {t.categories.all}
        </FilterButton>
        <FilterButton 
          $active={filter === 'android'} 
          onClick={() => handleFilterChange('android')}
        >
          {t.categories.android}
        </FilterButton>
        <FilterButton 
          $active={filter === 'ios'} 
          onClick={() => handleFilterChange('ios')}
        >
          {t.categories.ios}
        </FilterButton>
        <FilterButton 
          $active={filter === 'macos'} 
          onClick={() => handleFilterChange('macos')}
        >
          {t.categories.macos}
        </FilterButton>
        <FilterButton 
          $active={filter === 'flutter'} 
          onClick={() => handleFilterChange('flutter')}
        >
          {t.categories.flutter}
        </FilterButton>
        <FilterButton 
          $active={filter === 'react'} 
          onClick={() => handleFilterChange('react')}
        >
          {t.categories.react}
        </FilterButton>
        <FilterButton 
          $active={filter === 'dapp'} 
          onClick={() => handleFilterChange('dapp')}
        >
          {t.categories.dapp}
        </FilterButton>
      </FilterContainer>

      <ProjectGrid
        key={filter}
        variants={containerVariants}
        initial="hidden"
        animate={isFiltering ? "hidden" : "show"}
      >
        {filteredProjects.map((project) => (
          <ProjectCard
            key={project.id}
            variants={itemVariants}
            onClick={() => {
              if (project.id === 1) {
                navigate(`/projects/${project.slug}`);
              } else {
                navigate(`/projects/coming-soon/${project.category}`);
              }
            }}
            style={{ cursor: 'pointer' }}
            whileHover={{ y: -10 }}
          >
            <ProjectImageWrapper>
              <ProjectImage src={project.image} alt={project.title} />
            </ProjectImageWrapper>
            <ProjectContent>
              <ProjectTitle>{project.title}</ProjectTitle>
              <ProjectDescription>{project.description}</ProjectDescription>
              <TechStack>
                {project.tech.map((tech, index) => (
                  <TechTag key={index}>{tech}</TechTag>
                ))}
              </TechStack>
              <ProjectLinks>
                {project.github && (
                  <ProjectLink 
                    $disabled={!project.github.startsWith('http')}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (project.github.startsWith('http')) {
                        window.open(project.github, '_blank');
                      }
                    }}
                  >
                    <Github />
                  </ProjectLink>
                )}
                {project.playstore && (
                  <ProjectLink 
                    $disabled={!project.playstore.startsWith('http')}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (project.playstore.startsWith('http')) {
                        window.open(project.playstore, '_blank');
                      }
                    }}
                  >
                    <i className="fab fa-google-play" />
                  </ProjectLink>
                )}
                {project.appstore && (
                  <ProjectLink 
                    $disabled={!project.appstore.startsWith('http')}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (project.appstore.startsWith('http')) {
                        window.open(project.appstore, '_blank');
                      }
                    }}
                  >
                    <i className="fab fa-app-store-ios" />
                  </ProjectLink>
                )}
              </ProjectLinks>
            </ProjectContent>
          </ProjectCard>
        ))}
      </ProjectGrid>
    </ProjectsContainer>
  );
};

export default Projects;