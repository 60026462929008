import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider as CustomThemeProvider, useTheme } from './contexts/ThemeContext';
import { ThemeProvider } from 'styled-components';
import { LanguageProvider } from './contexts/LanguageContext';
import { darkTheme } from './styles/theme/darkTheme';
import { lightTheme } from './styles/theme/lightTheme';
import GlobalStyles from './styles/GlobalStyles';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import styled from 'styled-components';
import ProjectDetail from './pages/ProjectDetail';
import ScrollToTop from './components/ScrollToTop';
import ComingSoon from './pages/ComingSoon';
import NotFound from './pages/NotFound';
import License from './pages/License';
import { motion } from 'framer-motion';
import Donation from './pages/Donation';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const AppContent = () => {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <ScrollToTop />
        <AppContainer>
          <Navbar />
          <MainContent>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/:slug" element={<ProjectDetail />} />
              <Route path="/projects/coming-soon/:projectId" element={<ComingSoon />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/license" element={<License />} />
              <Route path="/donation" element={<Donation />} />
              <Route path="/coming-soon/:category" element={<ComingSoon />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </MainContent>
          <Footer />
        </AppContainer>
      </Router>
    </ThemeProvider>
  );
};

// shouldForwardProp 함수 수정
const shouldForwardProp = (prop) => {
  // framer-motion props 필터링
  const motionProps = [
    'variants',
    'initial',
    'animate',
    'exit',
    'transition',
    'whileHover',
    'whileTap',
    'whileInView',
    'whileFocus',
    'whileDrag',
    'onHoverStart',
    'onHoverEnd',
    'onAnimationStart',
    'onAnimationComplete'
  ];

  // styled-components prop이거나 motion prop이 아닌 경우만 true 반환
  return !prop.startsWith('$') && !motionProps.includes(prop);
};

function App() {
  return (
    <CustomThemeProvider>
      <LanguageProvider>
        <AppContent />
      </LanguageProvider>
    </CustomThemeProvider>
  );
}

export default App;
