import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Container } from '../styles/commonStyles';
import { Github, ExternalLink, Star } from 'lucide-react';
import { useParams, Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../locales/translations';
import { SiFlutter, SiDart, SiFirebase, SiAndroidstudio } from "react-icons/si";
import { FaAndroid, FaApple } from "react-icons/fa";

const DetailContainer = styled(motion.div)`
  padding: 120px 5% 60px;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0;
    padding-top: 70px;
    padding-bottom: 40px;
  }
`;

const AppHeader = styled(motion.div)`
  display: flex;
  margin-bottom: 4rem;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 1.5rem;
    padding: 20px;
  }
`;

const MobileTopSection = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    gap: 1.2rem;
    width: 100%;
    margin-bottom: 1.5rem;
  }
`;

const AppMeta = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: 0.8rem;
    font-size: 0.8rem;
    padding: 0;
  }
`;

const StarRating = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
  i {
    color: #FFD700;
  }

  @media (max-width: 768px) {
    font-size: 0.85rem;
  }
`;

const AppTitle = styled.h1`
  font-size: 2.5rem;
  color: ${props => props.theme.colors.text};
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    width: 100%;
    text-align: left;
    font-size: 1.6rem;
    margin-bottom: 0.3rem;
    line-height: 1.3;
  }
`;

const AppSubtitle = styled.p`
  font-size: 1.2rem;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    width: 100%;
    text-align: left;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    opacity: 0.7;
    line-height: 1.4;
  }
`;

const MobileAppInfo = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    padding-top: 0.2rem;

    ${AppMeta} {
      margin-bottom: 0.6rem;
      font-size: 0.85rem;
    }

    ${AppTitle} {
      font-size: 1.4rem;
      margin-bottom: 0.3rem;
      line-height: 1.2;
    }

    ${AppSubtitle} {
      font-size: 0.9rem;
      opacity: 0.8;
      margin-bottom: 0;
    }
  }
`;

const AppIconWrapper = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 40px;
  overflow: hidden;
  box-shadow: 0 10px 30px ${props => props.theme.colors.shadow};
  border: 1px solid ${props => props.theme.colors.border};
  background: ${props => props.theme.colors.card};
  flex-shrink: 0;
  margin-right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    margin: 0;
  }
`;

const AppIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  object-position: center;
`;

const DownloadButtons = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.8rem;
    width: 100%;
    margin-top: 1.5rem;
  }
`;

const DownloadButton = styled.a`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.8rem 1.5rem;
  background: ${props => 
    props.$store === 'apple' 
      ? '#000000' 
      : props.theme.colors.background === '#ffffff' 
        ? '#f5f5f7' 
        : '#2c2c2e'
  };
  color: ${props => 
    props.$store === 'apple' || props.theme.colors.background === '#000000'
      ? '#ffffff'
      : '#000000'
  };
  text-decoration: none;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
  min-width: 200px;
  border: 1px solid ${props => props.theme.colors.border};

  i {
    font-size: 1.8rem;
  }

  .download-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    .small-text {
      font-size: 0.7rem;
      opacity: 0.8;
    }
    
    .large-text {
      font-size: 1.1rem;
      font-weight: 600;
    }
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px ${props => props.theme.colors.shadow};
    background: ${props => 
      props.$store === 'apple'
        ? '#1a1a1a'
        : props.theme.colors.background === '#ffffff'
          ? '#e5e5e7'
          : '#3c3c3e'
    };
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    padding: 0.8rem;
    border-radius: 10px;
    height: 48px;
    box-shadow: 0 2px 8px ${props => props.theme.colors.shadow};
    
    i {
      font-size: 1.4rem;
      margin-right: 4px;
    }

    .download-text {
      margin-left: 8px;
      
      .small-text {
        font-size: 0.7rem;
        opacity: 0.8;
        margin-bottom: 1px;
      }
      
      .large-text {
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: -0.2px;
      }
    }

    &:active {
      transform: scale(0.98);
    }
  }
`;

const AppInfo = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${AppMeta} {
      width: 100%;
    }

    ${DownloadButtons} {
      max-width: 320px;
      width: 100%;
    }
  }
`;

const PreviewSection = styled(motion.section)`
  margin-bottom: 4rem;
  text-align: left;

  @media (max-width: 768px) {
    margin-bottom: 2rem;
    padding-top: 0.5rem;
    padding: 0 20px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 2rem;
  color: ${props => props.theme.colors.text};

  @media (max-width: 768px) {
    font-size: 1.3rem;
    margin-bottom: 0.8rem;
    padding: 0;
    font-weight: 600;
  }
`;

const PreviewGallery = styled.div`
  display: flex;
  gap: 2rem;
  overflow-x: auto;
  padding: 1rem 0;
  scrollbar-width: thin;
  scrollbar-color: ${props => `${props.theme.colors.accent} ${props.theme.colors.tertiary}`};

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.tertiary};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.colors.accent};
    border-radius: 4px;
    
    &:hover {
      background-color: ${props => props.theme.colors.accent}dd;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
    padding: 0;
    margin: 0;
    overflow-x: visible;
    overflow: visible;
    
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const PreviewImage = styled.img`
  width: 300px;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 5px 15px ${props => props.theme.colors.shadow};
  border: 1px solid ${props => props.theme.colors.border};
  background: ${props => props.theme.colors.card};
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 6px ${props => props.theme.colors.shadow};
    aspect-ratio: auto;
    object-fit: contain;
  }
`;

const ImageWrapper = styled(motion.div)`
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
  }
`;

const AppDescription = styled(motion.section)`
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    margin-bottom: 2rem;
    padding: 0 20px;
    
    p {
      font-size: 0.9rem;
      line-height: 1.6;
      opacity: 0.9;
    }
  }
`;

const PromotionText = styled.p`
  font-size: 1.2rem;
  color: ${props => props.theme.colors.accent};
  margin-bottom: 1.5rem;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    margin-bottom: 0.8rem;
    line-height: 1.5;
    font-weight: 600;
  }
`;

const KeyFeatures = styled.div`
  margin-top: 2rem;

  @media (max-width: 768px) {
    margin-top: 1.2rem;
    
    h3 {
      font-size: 1.1rem;
      margin-bottom: 0.6rem;
      font-weight: 600;
    }
  }
`;

const FeatureList = styled.ul`
  list-style: none;
  padding-left: 0;

  li {
    margin-bottom: 0.8rem;
    padding-left: 1.5rem;
    position: relative;
    color: ${props => props.theme.colors.text};
    font-size: 1rem;

    @media (max-width: 768px) {
      font-size: 0.85rem;
      margin-bottom: 0.5rem;
      line-height: 1.5;
      padding-left: 1.2rem;
      
      &::before {
        font-size: 1rem;
        top: -1px;
      }
    }

    &::before {
      content: "•";
      color: ${props => props.theme.colors.accent};
      position: absolute;
      left: 0;
      font-weight: bold;
    }
  }
`;

const TechStack = styled(motion.section)`
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    margin-bottom: 2rem;
    padding: 0 20px;
  }
`;

const TechGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;

  @media (max-width: 768px) {
    gap: 0.8rem;
  }
`;

const TechItem = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1.2rem;
  background: ${props => props.theme.colors.card};
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.border};
  transition: all 0.2s ease;

  i {
    font-size: 1.2rem;
    color: ${props => props.theme.colors.accent};
  }

  span {
    font-size: 0.95rem;
    color: ${props => props.theme.colors.text};
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px ${props => props.theme.colors.shadow};
  }

  @media (max-width: 768px) {
    padding: 0.6rem 1rem;
    
    i {
      font-size: 1rem;
    }
    
    span {
      font-size: 0.85rem;
    }
  }
`;

const fadeInUp = {
  initial: { 
    opacity: 0, 
    y: 20 
  },
  animate: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.6,
      ease: [0.6, -0.05, 0.01, 0.99]
    }
  }
};

const staggerContainer = {
  animate: {
    transition: {
      staggerChildren: 0.1
    }
  }
};

const MobileButtons = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    width: 100%;
  }
`;

const DesktopLayout = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ProjectDetail = () => {
  const { slug } = useParams();
  const { language } = useLanguage();
  
  // 하드코딩된 번역 데이터
  const t = {
    preview: language === 'ko' ? "앱 미리보기" : "App Preview",
    introduction: language === 'ko' ? "앱 소개" : "App Overview",
    reviews: {
      count: language === 'ko' ? "개의 평가" : "reviews"
    },
    downloadButtons: {
      appStore: {
        small: language === 'ko' ? "다운로드" : "Download on the",
        large: "App Store"
      },
      playStore: {
        small: language === 'ko' ? "다운로드" : "GET IT ON",
        large: "Google Play"
      }
    },
    features: {
      title: language === 'ko' ? "주요 기능" : "Key Features",
      list: language === 'ko' ? [
        "영어 단어를 쉽게 외울수 있다.",
        "영어 단어를 반복적으로 외울수 있다.",
        "맞춤형 복습 시스템"
      ] : [
        "Easy and intuitive vocabulary learning",
        "Spaced repetition learning system",
        "Personalized review schedule"
      ]
    },
    techStack: {
      title: language === 'ko' ? "사용된 기술" : "Technologies Used",
      items: [
        { name: "Flutter", icon: "flutter" },
        { name: "Dart", icon: "code" },
        { name: "Firebase", icon: "database" },
        { name: "Android Studio", icon: "tools" }
      ]
    },
    promotion: language === 'ko' ? "새로운 업데이트가 소개될 예정입니다." : "New update coming soon!"
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // 프로젝트 데이터 매핑
  const projectsData = {
    english: {
      id: 1,
      title: language === 'ko' ? "영어 단어 외우기" : "English Vocabulary Builder",
      subtitle: language === 'ko' 
        ? "영어 단어 외우는 앱을 제작중 입니다." 
        : "An intuitive app for mastering English vocabulary",
      icon: "/images/pr-en-d.jpeg",
      category: "Flutter",
      rating: 4.8,
      reviews: language === 'ko' ? "1.2만" : "12K",
      description: language === 'ko'
        ? "영어 단어를 외우기 위한 앱 입니다."
        : "An effective tool designed to enhance your English vocabulary learning experience",
      promotion: t.promotion,
      features: t.features.list,
      tech: t.techStack.items,
      screenshots: [
        "/images/pr-en-d.jpeg",
        "/images/pr-en-d.jpeg",
        "/images/pr-en-d.jpeg"
      ],
      appStore: "",
      playStore: ""
    },
    // 다른 프로젝트들도 여기에 추가할 수 있습니다
  };

  // slug에 해당하는 프로젝트 데��터 가져오기
  const projectData = projectsData[slug];

  useEffect(() => {
    if (projectData) {
      document.title = `${projectData.title} - CEMARDZ`;
    }
    return () => {
      document.title = 'CEMARDZ';
    };
  }, [projectData]);

  // 프로젝트를 찾을 수 없는 경우 404 페이지로 리다이렉트
  if (!projectData) {
    return <Navigate to="/404" />;
  }

  const techIconComponent = {
    'flutter': SiFlutter,
    'code': SiDart,
    'database': SiFirebase,
    'tools': SiAndroidstudio,
    'android': FaAndroid,
    'apple': FaApple
  };

  return (
    <DetailContainer
      initial="initial"
      animate="animate"
      variants={staggerContainer}
    >
      <AppHeader variants={fadeInUp}>
        <MobileTopSection>
          <AppIconWrapper>
            <AppIcon src={projectData.icon} alt={projectData.title} />
          </AppIconWrapper>
          <MobileAppInfo>
            <AppMeta>
              <span>{projectData.category}</span>
              <StarRating>
                <i className="fas fa-star"></i>
                {projectData.rating} ({projectData.reviews} {t.reviews.count})
              </StarRating>
            </AppMeta>
            <AppTitle>{projectData.title}</AppTitle>
            <AppSubtitle>{projectData.subtitle}</AppSubtitle>
          </MobileAppInfo>
        </MobileTopSection>

        <MobileButtons>
          <DownloadButtons>
            <DownloadButton href={projectData.appStore} $store="apple">
              <i className="fab fa-app-store-ios"></i>
              <div className="download-text">
                <span className="small-text">{t.downloadButtons.appStore.small}</span>
                <span className="large-text">{t.downloadButtons.appStore.large}</span>
              </div>
            </DownloadButton>
            <DownloadButton href={projectData.playStore} $store="google">
              <i className="fab fa-google-play"></i>
              <div className="download-text">
                <span className="small-text">{t.downloadButtons.playStore.small}</span>
                <span className="large-text">{t.downloadButtons.playStore.large}</span>
              </div>
            </DownloadButton>
          </DownloadButtons>
        </MobileButtons>

        <DesktopLayout>
          <AppIconWrapper>
            <motion.div
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <AppIcon src={projectData.icon} alt={projectData.title} />
            </motion.div>
          </AppIconWrapper>
          <AppInfo>
            <motion.div variants={fadeInUp}>
              <AppTitle>{projectData.title}</AppTitle>
              <AppSubtitle>{projectData.subtitle}</AppSubtitle>
              <AppMeta>
                <span>{projectData.category}</span>
                <StarRating>
                  <i className="fas fa-star"></i>
                  {projectData.rating} ({projectData.reviews} {t.reviews.count})
                </StarRating>
              </AppMeta>
            </motion.div>
            <motion.div variants={fadeInUp} transition={{ delay: 0.3 }}>
              <DownloadButtons>
                <DownloadButton href={projectData.appStore} $store="apple">
                  <i className="fab fa-app-store-ios"></i>
                  <div className="download-text">
                    <span className="small-text">{t.downloadButtons.appStore.small}</span>
                    <span className="large-text">{t.downloadButtons.appStore.large}</span>
                  </div>
                </DownloadButton>
                <DownloadButton href={projectData.playStore} $store="google">
                  <i className="fab fa-google-play"></i>
                  <div className="download-text">
                    <span className="small-text">{t.downloadButtons.playStore.small}</span>
                    <span className="large-text">{t.downloadButtons.playStore.large}</span>
                  </div>
                </DownloadButton>
              </DownloadButtons>
            </motion.div>
          </AppInfo>
        </DesktopLayout>
      </AppHeader>

      <PreviewSection variants={fadeInUp}>
        <SectionTitle>{t.preview}</SectionTitle>
        <PreviewGallery>
          {projectData.screenshots.map((screenshot, index) => (
            <ImageWrapper
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 + index * 0.1 }}
            >
              <PreviewImage 
                src={screenshot} 
                alt={`${projectData.title} 스크린샷 ${index + 1}`}
                loading="lazy"
              />
            </ImageWrapper>
          ))}
        </PreviewGallery>
      </PreviewSection>

      <AppDescription variants={fadeInUp}>
        <SectionTitle>{t.introduction}</SectionTitle>
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4, delay: 0.2 }}
        >
          <PromotionText>{projectData.promotion}</PromotionText>
          <p>{projectData.description}</p>
        </motion.div>
        <KeyFeatures>
          <motion.h3
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.3 }}
          >
            {t.features.title}
          </motion.h3>
          <FeatureList>
            {projectData.features.map((feature, index) => (
              <motion.li
                key={index}
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.4, delay: 0.4 + index * 0.1 }}
              >
                {feature}
              </motion.li>
            ))}
          </FeatureList>
        </KeyFeatures>
      </AppDescription>

      <TechStack variants={fadeInUp}>
        <SectionTitle>{t.techStack.title}</SectionTitle>
        <TechGrid>
          {projectData.tech.map((tech, index) => (
            <TechItem
              key={index}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
            >
              {React.createElement(techIconComponent[tech.icon] || SiDart, { 
                size: 20,
                style: { color: props => props.theme.colors.accent }
              })}
              <span>{tech.name}</span>
            </TechItem>
          ))}
        </TechGrid>
      </TechStack>
    </DetailContainer>
  );
};

export default ProjectDetail; 